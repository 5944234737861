import React, { Component } from "react";
import axios from "axios";
import { apiURL } from "../../services/config";
class SubscribeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: "",
      },
      successMessage: "Vous êtes inscrit à la newsletter!",
      warningMessage: "Vous êtes déjà inscrit à la newsletter!",
      errorMessage: "Quelque chose ne va pas. Réessayez plus tard!",
      alertClass: "",
      responseMessage: "",
      alertTimeout: "",
      delay: 5000,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }

    this.setState({ isSubmitting: true });

    const response = await axios.post(
      `${apiURL}/newsletter/create`,
      this.state.values,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json; charset=UTF-8",
        },
      }
    );
    if (response.data.status === "success") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
        },
      });
      this.callAlert(this.state.successMessage, response.data.status);
      return;
    }

    if (response.data.error === "exist") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
        },
      });

      this.callAlert(this.state.warningMessage, response.data.error);
      return;
    }

    if (response.data.error === "error") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
        },
      });
      this.callAlert(this.state.errorMessage, response.data.error);
      return;
    }
  };

  removeAlert = () => {
    clearTimeout(this.state.alertTimeout);

    this.setState({
      alertTimeout: setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");

        setTimeout(function () {
          element.remove();
        }, 900);
      }, this.state.delay),
    });
  };

  callAlert = (message, type) => {
    let alertClass = "";
    if (!document.querySelector("#alert")) {
      if (type === "success") {
        alertClass = "success";
      }
      if (type === "exist") {
        alertClass = "warning";
      }
      if (type === "error") {
        alertClass = "danger";
      }

      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector(".newsletter-form");

      element.insertAdjacentHTML("beforeend", alert);

      this.removeAlert();
    }
  };

  handleInputChange = (e) =>
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });

  render() {
    return (
      <form onSubmit={this.submitForm} className="newsletter-form top-no-space">
        <div className="input-group m-auto">
          <input
            name="email"
            value={this.state.values.email}
            onChange={this.handleInputChange}
            id="cf-2-email"
            type="email"
            placeholder="Votre adresse email"
            required="required"
            className="mb-3"
          />
          <button type="submit" className="btn btn-sm btn-secondary m-auto">
            Inscription
          </button>
        </div>
      </form>
    );
  }
}

export default SubscribeForm;
