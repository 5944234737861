import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import { useParams } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../services/config";
import ResetPasswordForm from "../components/form/ResetPasswordForm";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  document.body.classList.add("reset-password");

  const { token } = useParams();
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${apiURL}/users/reset-password/${token}`)
      .then((res) => {
        !res.data.result && history.push("/404");
      })
      .catch((err) => {
        console.log("err", err);
        history.push("/404");
      });
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Réinitialisation mot de passe | Julie Bengueddach - Esthéticienne
          professionelle
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Offrez-vous des soins visages, soins corps, épilations, microblading, microshading, maquillage, manucure et beauté des pieds avec Julie Bengueddach, esthéticienne professionnelle à domicile à Igny, France."
        />
        <meta
          name="keywords"
          content="esthéticienne, domicile, Igny, soins visages, soins corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds"
        />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <div id="page-content" className="spacer p-top-lg">
          <div className="wrapper">
            <div className="content">
              <ResetPasswordForm token={token} />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ResetPassword;
