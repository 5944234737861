import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

const MapBox = () => {
  const icone = new Icon({
    iconUrl: "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681662660/JB_1_qwfbh1.png",
    iconSize: [50, 50],
  });

  return (
    <MapContainer
      style={{ width: "100%", height: "500px" }}
      scrollWheelZoom={false}
      center={[48.7322286, 2.222229]}
      zoom={13}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors"
      />
      <Marker position={[48.7322286, 2.222229]} icon={icone}>
        <Popup>Institut de Julie</Popup>
      </Marker>
    </MapContainer>
  );
};

export default MapBox;
