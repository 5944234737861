import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import Contacts from "./pages/Contacts";
import Gallery from "./pages/Gallery";
import Services from "./pages/Services";
import ServicesInside from "./pages/ServicesInside";
import page404 from "./pages/404";
import ReservationPage from "./pages/ReservationPage";
import UserProfil from "./pages/UserProfil";
import ResetPassword from "./pages/ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./reducers/user";
import api from "./services/api";
import Loading from "./blocks/loading/Loading";

function Navigation() {
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!user.token) return setLoading(false);
        if (user.token) api.setToken(user.token);
        const response = await api.post("/users/signin/token", {
          token: user.token,
        });
        if (!response.result || !response.user) return setLoading(false);
        dispatch(
          login({
            token: response.token,
            firstname: response.firstname,
            lastname: response.lastname,
          })
        );
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const RestrictedRoute = ({ component: Component, role, ...rest }) => {
    const user = useSelector((state) => state.user.value);
    if (!user.token) return <Redirect to={{ pathname: "/" }} />;
    return (
      <Route
        {...rest}
        render={(props) =>
          user.token ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        }
      />
    );
  };

  if (loading) return <Loading />;
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/"}`}
          component={Home}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contacts"}`}
          component={Contacts}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/gallery"}`}
          component={Gallery}
        />

        <RestrictedRoute exact path="/profile" component={UserProfil} />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/services"}`}
          component={Services}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/services-inside/:id"}`}
          component={ServicesInside}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/reservation-page"}`}
          component={ReservationPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/reset-password/:token"}`}
          component={ResetPassword}
        />
        <Route exact component={page404} />
      </Switch>
    </Router>
  );
}

export default Navigation;
