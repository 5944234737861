import React from "react";
const ContactsUs = () => {
  return (
    <section id="contact-us" className="block">
      <div className="spacer p-top-xl p-bottom-xl">
        <div className="wrapper text-center">
          <div className="title">
            <h2>Vous pouvez réserver ci-dessous</h2>
          </div>
          <a
            href="https://widget.treatwell.fr/salon/100051594/menu/"
            className="btn btn-outline-secondary"
          >
            Réservation
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactsUs;
