import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";

class ServicesHome extends Component {
  componentDidMount() {
    new Swiper(".adv-slider-services .adv-swiper-container", {
      // ADV swiper
      noSwipingClass: "adv-swiper-no-swiping",
      containerModifierClass: "adv-swiper-container-",
      slideClass: "adv-swiper-slide",
      slideBlankClass: "adv-swiper-slide-invisible-blank",
      slideActiveClass: "adv-swiper-slide-active",
      slideDuplicateActiveClass: "adv-swiper-slide-duplicate-active",
      slideVisibleClass: "adv-swiper-slide-visible",
      slideDuplicateClass: "adv-swiper-slide-duplicate",
      slideNextClass: "adv-swiper-slide-next",
      slideDuplicateNextClass: "adv-swiper-slide-duplicate-next",
      slidePrevClass: "adv-swiper-slide-prev",
      slideDuplicatePrevClass: "adv-swiper-slide-duplicate-prev",
      wrapperClass: "adv-swiper-wrapper",
      navigation: {
        disabledClass: "adv-swiper-button-disabled",
        hiddenClass: "adv-swiper-button-hidden",
        lockClass: "adv-swiper-button-lock",
        nextEl: ".adv-2-swiper-button-next",
        prevEl: ".adv-2-swiper-button-prev",
      },
      spaceBetween: 0,
      slidesPerView: "auto",
    });
  }

  render() {
    return (
      <section id="services" className="block">
        <div className="adv-slider-services">
          <div className="adv-swiper-container">
            <div className="adv-swiper-wrapper services-items clearfix">
              <a
                title="Face & Body"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/1"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">Forfaits</h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1687861237/iStock-1124450997_bnn6ia.jpg"
                      alt="Forfaits"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>
              <a
                title="Face & Body"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/2"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">Épilation</h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683574374/iStock-495621934_vjsrfz.jpg"
                      alt="Épilation"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a
                title="Hair Treatments"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/3"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">Soins du corps</h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683574544/iStock-1453431340_ez8d9c.jpg"
                      alt="Soins du corps"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a
                title="Hands & Feets"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/4"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">
                    Microblading / Microshading
                  </h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683574772/iStock-1043619114_duhq9w.jpg"
                      alt="Microblading / Microshading"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>

              <a
                title="Haircuts for Men"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/5"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">Soins du visage</h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683574924/iStock-1223102011_ugpz2y.jpg"
                      alt="Soins du visage"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>
              <a
                title="Face & Body"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/6"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">
                    Manucure / Beauté des pieds
                  </h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575058/iStock-1441893954_h1w0v6.jpg"
                      alt="Manucure / Beauté des pieds"
                    />
                  </div>
                </div>

                <div className="img-bg-color"></div>
              </a>
              <a
                title="Face & Body"
                className="adv-swiper-slide services-item"
                href={process.env.PUBLIC_URL + "/services-inside/6"}
              >
                <div className="services-item-content">
                  <h3 className="services-item-t-head">
                    Maquillage Cils et Sourcils
                  </h3>

                  <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">
                    en savoir plus
                  </span>
                </div>

                <div className="img object-fit">
                  <div className="object-fit-cover">
                    <img
                      loading="lazy"
                      src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575222/iStock-1284775774_je6sae.jpg"
                      alt="Maquillage Cils et Sourcils"
                    />
                  </div>
                </div>
                <div className="img-bg-color"></div>
              </a>
            </div>
            <div className="adv-2-swiper-button-prev">
              <span>
                <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">
                  Précédent
                </span>
              </span>
            </div>
            <div className="adv-2-swiper-button-next">
              <span>
                <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">
                  Suivant
                </span>
              </span>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ServicesHome;
