import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleGallery from "../blocks/page-title/PageTitleGallery";
import GalleryContent from "../blocks/gallery/GalleryContent";

const Gallery = () => {
  document.body.classList.add("page");

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Galerie | Julie Bengueddach - Esthéticienne professionelle
        </title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Découvrez les avant/après des différentes prestations d'esthétique proposées par Julie Bengueddach, esthéticienne professionnelle à Igny. Soins du visage et du corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds et bien plus encore !"
        />
        <meta
          name="keywords"
          content="esthéticienne à domicile, soins du visage, soins du corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds, beauté, bien-être, Igny, Essonne"
        />

        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656789/43c183f7-e6ee-404d-bc2f-a989ad5b066e_2_skadwh.jpg"
        />
      </MetaTags>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <PageTitleGallery />
        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div className="clearfix">
                <GalleryContent />
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Gallery;
