import React, { Fragment } from "react";
import ServiceCard from "../components/card/ServiceCard";
import servicesData from "../data/services/servicesContentItems.json";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import PageTitleReservation from "../blocks/page-title/PageTitleReservation";
import toast, { Toaster } from "react-hot-toast";

const ReservationPage = () => {
  // Mapper les données pour générer les cartes de service
  const serviceCards = servicesData.map((service) => {
    return (
      <ServiceCard
        key={service.id}
        title={service.title}
        link={service.link}
        imgSrc={service.imgSrc}
        soins={service.soins}
      />
    );
  });

  return (
    <Fragment>
      <Toaster />
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Réservation | Julie Bengueddach - Esthéticienne professionelle
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Prenez rendez-vous avec Julie Bengueddach, esthéticienne professionnelle à domicile située à Igny, pour des soins visages, corps, épilation, microblading, microshading, maquillage et manucure/beauté des pieds."
        />
        <meta
          name="keywords"
          content="esthéticienne à domicile, Igny, soins visages, soins corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds, prendre rendez-vous, réservation"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <div>
          <PageTitleReservation />

          <div id="description" className="block p-top-xl">
            <div
              className="description mt-5"
              style={{ width: "70%", margin: "auto" }}
            >
              <p>
                Vous pouvez réserver en ligne pour profiter de nos services de
                soins esthétiques de haute qualité. Julie vous accueille dans un
                cadre chaleureux et relaxant pour vous offrir des moments de
                détente et de bien-être.
              </p>
              <p>
                Nous vous proposons des créneaux de rendez-vous du lundi au
                vendredi, de 9h30 à 18h00 et le mercredi de 11h00 à 17h30.
              </p>
              <p>
                Découvrez ci-dessous nos différentes prestations disponibles :
              </p>
            </div>
          </div>

          <div
            className="service-card-container mt-3"
            style={{
              gap: "1rem",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {serviceCards}
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ReservationPage;
