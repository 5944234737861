import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import HeaderLogo from "../../blocks/logo/HeaderLogo";
import Actions from "../../blocks/actions/Actions";
import SignInForm from "../form/SignInForm";
import SignUpForm from "../form/SignUpForm";
import ForgotPasswordForm from "../form/ForgotPasswordForm";

class SearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      currentForm: "signIn", // Ajouter une nouvelle propriété state
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }
  toggleForm(currentForm) {
    this.setState({
      currentForm: currentForm,
    });
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <Fragment>
        <div className="search-toggle">
          {/* <button
            onClick={this.open.bind(this)}
            className={
              this.props.text
                ? "btn btn-sm btn-primary"
                : "btn btn-lg btn-link border-0 p-0 min-w-auto"
            }
            data-toggle="modal"
            data-target="#search-modal"
          >
            {this.props.reservation ? (
              <span
                className="text-light"
              >
                {this.props.text ? this.props.text : "Se connecter"}
              </span>
            ) : (
              <span
                className={
                  this.props.logoColor === "dark" ? "text-light" : "text-dark"
                }
              >
                {this.props.text ? this.props.text : "Se connecter"}
              </span>
            )}
          </button> */}
        </div>

        <Modal
          className="search-modal"
          id="search-modal"
          show={this.state.showModal}
          onHide={this.close}
          backdrop={false}
          aria-labelledby="search-modal"
          aria-hidden="true"
          dialogClassName="modal-full"
          ref={(node) => (this.chart = node)}
        >
          <div className="wrapper">
            <div className="modal-content">
              <div className="modal-header modal-header-top">
                <div className="header-content d-flex justify-content-between w-100">
                  <div className="header-left align-self-center">
                    <div className="links">
                      <div className="links-items">
                        <div className="links-item">
                          <a
                            href={process.env.PUBLIC_URL + "/"}
                            className="btn btn-lg btn-link border-0 p-0 min-w-auto"
                          >
                            Accueil
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-center align-self-center">
                    <HeaderLogo logoColor="light" />
                  </div>

                  <div className="header-right d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                      <div className="search-close-toggle">
                        <button
                          onClick={this.close}
                          type="button"
                          className="btn btn-lg btn-link border-0 p-0 min-w-auto"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Fermer
                        </button>
                      </div>
                    </div>

                    <Actions />
                  </div>
                </div>
              </div>
              {/* Rajouter un message qui permet de faire comprendre à l'utilisateur qu'il faut être connecter pour pouvoir réserver */}
              <div className="modal-body modal-body-centered">
                {this.state.currentForm === "signIn" ? (
                  <SignInForm
                    toggleForm={this.toggleForm}
                    close={this.close}
                    page={this.props.page}
                  />
                ) : this.state.currentForm === "signUp" ? (
                  <SignUpForm
                    toggleForm={this.toggleForm}
                    close={this.close}
                    page={this.props.page}
                  />
                ) : (
                  <ForgotPasswordForm
                    toggleForm={this.toggleForm}
                    close={this.close}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default SearchModal;
