import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import Calendar from "react-calendar";
import "../../assets/sass/customs/_calendar.scss";
import { connect } from "react-redux";
import api from "../../services/api";
import Confetti from "react-confetti";
class ReservationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedDate: new Date(),
      slots: [],
      showSlots: false,
      confirmedSlot: null,
      validateSlot: false,
      error: null,
      isLoading: false,
    };
    this.props = props;
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleGenerateSlots = this.handleGenerateSlots.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBooking = this.handleBooking.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false, selectedDate: new Date() });
  }

  handleDateChange(date) {
    this.setState({ selectedDate: date });
  }

  async handleBooking(startTime, endTime) {
    this.setState({ isLoading: true });
    // send post request for booking with this informations date, startTime, endTime, serviceDuration
    const selectedDate = this.state.selectedDate;
    const annee = selectedDate.getFullYear(); // Année
    const mois = String(selectedDate.getMonth() + 1).padStart(2, "0"); // Mois (ajouter 1 car les mois commencent à partir de 0, puis padStart pour ajouter un zéro devant si nécessaire)
    const jour = String(selectedDate.getDate()).padStart(2, "0"); // Jour (padStart pour ajouter un zéro devant si nécessaire)

    const dateFormatee = `${annee}-${mois}-${jour}`;
    try {
      const response = await api.post("/bookings/create", {
        date: dateFormatee,
        startTime: startTime,
        endTime: endTime,
        serviceDuration: this.props.product.time,
        duration: this.props.product.time,
        price: this.props.product.price,
        soin: this.props.title,
        service: this.props.service,
      });
      if (response.result) {
        this.setState({ validateSlot: true, isLoading: false });
        return;
      }

      if (response.error) {
        this.setState({ error: response.error, isLoading: false });
        return;
      }
    } catch (error) {
      console.error("Erreur lors de la réservation :", error);
      this.setState({ error: error, isLoading: false });
    }
  }

  handleCancel() {
    this.setState({
      showModal: false,
      showSlots: false,
      slots: [],
      selectedDate: new Date(),
      confirmedSlot: null,
      validateSlot: false,
      error: null,
    });
  }

  async handleGenerateSlots() {
    // Récupérer la date sélectionnée
    const selectedDate = this.state.selectedDate;
    const annee = selectedDate.getFullYear(); // Année
    const mois = String(selectedDate.getMonth() + 1).padStart(2, "0"); // Mois (ajouter 1 car les mois commencent à partir de 0, puis padStart pour ajouter un zéro devant si nécessaire)
    const jour = String(selectedDate.getDate()).padStart(2, "0"); // Jour (padStart pour ajouter un zéro devant si nécessaire)

    const dateFormatee = `${annee}-${mois}-${jour}`;

    // Vérifier si une date est sélectionnée
    if (dateFormatee) {
      // Faire la requête à la route pour obtenir les slots disponibles
      try {
        const response = await api.get(
          `/slots/available-slots?date=${dateFormatee}&serviceDuration=${this.props.product.time}`
        );
        if (response.result) {
          this.setState({ showSlots: true });
          // Mettre à jour l'état avec les slots disponibles
          this.setState({ slots: response.slots });
          return;
        }

        if (response.error) {
          console.error(
            "Erreur lors de la récupération des slots :",
            response.error
          );
          return;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des slots :", error);
      }
    } else {
      console.error("Aucune date sélectionnée.");
    }
  }

  render() {
    const { selectedDate, confirmedSlot, validateSlot, error, isLoading } =
      this.state;
    const { username } = this.props.user;
    const minDate = new Date();

    return (
      <Fragment>
        <button
          className="btn btn-sm btn-primary"
          onClick={this.open}
          style={{ cursor: "pointer" }}
        >
          Prix {this.props.product.price} €
        </button>
        <Modal
          className="search-modal"
          id="reservation-modal"
          show={this.state.showModal}
          onHide={this.close}
          backdrop={false}
          aria-labelledby="search-modal"
          aria-hidden="true"
          dialogClassName="modal-full"
          ref={(node) => (this.chart = node)}
        >
          <div className="wrapper">
            <div className="modal-content">
              <div className="modal-body modal-body-centered justify-content-center">
                {confirmedSlot && (
                  <div
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#F0EEE9",
                      padding: "20px",
                      borderRadius: "7px",
                      color: "#000",
                    }}
                    className="recap-div"
                  >
                    {!validateSlot ? (
                      <>
                        <h6>Confirmer votre rendez-vous</h6>
                        <p>Heure: {confirmedSlot.time}</p>
                        <p>Durée: {this.props.product.time} minutes</p>
                        <p>Service: {this.props.service}</p>
                        <p>Prestation: {this.props.title}</p>
                        <p>Prix: {this.props.product.price} €</p>

                        <img
                          loading="lazy"
                          src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656873/e2a4300d-a356-4f19-9b59-04cea76c842a_4_bls0a2.jpg"
                          alt="Avatar de l'esthéticienne"
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                        <h6 style={{ marginTop: "10px" }}>
                          Votre rendez-vous sera avec Julie Bengueddach !
                        </h6>
                        {error && <p style={{ color: "red" }}>{error}</p>}

                        {!isLoading ? (
                          <div>
                            <button
                              className="btn btn-sm btn-primary mr-2 text-white"
                              onClick={this.handleCancel}
                            >
                              Annuler
                            </button>

                            <button
                              className="btn btn-sm btn-primary ml-2 text-white"
                              onClick={() => {
                                this.handleBooking(
                                  confirmedSlot.time,
                                  confirmedSlot.endTime
                                );
                              }}
                            >
                              Confirmer
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div>
                        <Confetti
                          numberOfPieces={1000}
                          colors={["#567D78", "#FFF9F5", "#0000", "#ffff"]}
                          recycle={false}
                        />
                        <h6>Récapitulatif de votre rendez-vous</h6>
                        <p>
                          {" "}
                          <span style={{ fontWeight: "bold" }}>
                            Service:
                          </span>{" "}
                          {this.props.service}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Prestation:
                          </span>{" "}
                          {this.props.title}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>Prix:</span>{" "}
                          {this.props.product.price} €
                        </p>

                        <div className="mt-2">
                          <p className="mt-2">
                            Merci
                            <span className="text-primary">
                              {username}
                            </span>{" "}
                            d'avoir confirmé votre rendez-vous. Vous avez
                            réservé un créneau de {this.props.product.time}{" "}
                            minutes à {confirmedSlot.time} le{" "}
                            {selectedDate.toLocaleDateString("fr-FR", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                            . J'ai hâte de vous accueillir dans mon salon.
                          </p>
                          <p>
                            Si vous avez des questions ou besoin d'annuler ou de
                            reprogrammer votre rendez-vous, n'hésitez pas à me
                            contacter. Toute les informations pour la
                            réservation vous sont envoyer par mail.
                          </p>
                        </div>
                        <div className="py-3 mt-2 d-flex flex-column align-items-center">
                          <img
                            loading="lazy"
                            src="https://res.cloudinary.com/teammates/image/upload/q_40/v1680355226/e2a4300d-a356-4f19-9b59-04cea76c842a_2_rqwe2d.jpg"
                            alt="Avatar de l'esthéticienne"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "50%",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <p>Au plaisir de vous voir bientôt, Julie !</p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-sm btn-primary ml-2 text-white"
                            onClick={this.handleCancel}
                          >
                            Fermer
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {!confirmedSlot && (
                  <div className="d-flex flex-column align-items-center">
                    {!this.state.showSlots ? (
                      <div>
                        <h6 className="text-light">Choisissez une date :</h6>
                        <div className="d-flex justify-content-center">
                          <Calendar
                            value={selectedDate}
                            onChange={this.handleDateChange}
                            tileClassName={({ date, view }) =>
                              view === "month" && date <= minDate
                                ? "disabled"
                                : date.toDateString() ===
                                  selectedDate?.toDateString()
                                ? "selected"
                                : ""
                            }
                            minDate={minDate}
                            tileDisabled={({ date, view }) => {
                              // Désactiver les jours autres que lundi, mardi, jeudi et vendredi
                              return (
                                view === "month" &&
                                !(
                                  date.getDay() === 1 ||
                                  date.getDay() === 2 ||
                                  date.getDay() === 4 ||
                                  date.getDay() === 5
                                )
                              );
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Affichage des slots disponibles */}
                        {this.state.slots.length > 0 ? (
                          <div>
                            <h6 className="text-light">
                              Choisissez un créneau :
                            </h6>
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "500px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "#F0EEE9",
                                padding: "20px",
                                borderRadius: "7px",
                              }}
                            >
                              <h6>
                                Disponibilités pour {this.props.title}, le
                                {String(selectedDate.getDate()).padStart(
                                  2,
                                  "0"
                                )}
                                /
                                {String(selectedDate.getMonth() + 1).padStart(
                                  2,
                                  "0"
                                )}
                              </h6>
                              <div>
                                <button
                                  className="btn btn-sm btn-secondary"
                                  onClick={() => {
                                    this.setState({
                                      showSlots: false,
                                      slots: [],
                                    });
                                  }}
                                >
                                  Changer de date
                                </button>
                              </div>
                              <ul
                                className="d-flex flex-wrap justify-content-center align-items-center list-unstyled"
                                style={{
                                  gap: "10px",
                                  width: "100%",
                                  maxWidth: "100%",
                                }}
                              >
                                {this.state.slots.map((slot, id) => (
                                  <li key={id} className="my-2">
                                    <button
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        this.setState({
                                          confirmedSlot: {
                                            date: selectedDate,
                                            time: slot.startTime,
                                            endTime: slot.endTime,
                                          },
                                          showSlots: false,
                                          slots: [],
                                        });
                                      }}
                                    >
                                      {slot.startTime}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              maxWidth: "500px",
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              backgroundColor: "#F0EEE9",
                              padding: "20px",
                              borderRadius: "7px",
                            }}
                          >
                            <h6>
                              Aucune disponibilité pour {this.props.title}, le
                              {String(selectedDate.getDate()).padStart(2, "0")}/
                              {String(selectedDate.getMonth() + 1).padStart(
                                2,
                                "0"
                              )}
                              :
                            </h6>
                            <button
                              className="btn btn-sm btn-secondary"
                              onClick={() => {
                                this.setState({ showSlots: false, slots: [] });
                              }}
                            >
                              Changer de date
                            </button>
                          </div>
                        )}
                      </>
                    )}

                    {!this.state.showSlots && (
                      <div className="d-flex mt-3">
                        <button
                          className="btn btn-sm btn-primary mr-2"
                          onClick={this.close}
                        >
                          Fermer
                        </button>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={this.handleGenerateSlots}
                          disabled={!selectedDate}
                        >
                          Générer les disponibilités
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.value,
  };
};

export default connect(mapStateToProps, null)(ReservationModal);
