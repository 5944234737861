import React from "react";

const HeaderLogo = ({ logoColor }) => {
  return (
    <div className="header-logo">
      <a
        className="logo logo-secondary transform-scale-h"
        title="Logo"
        href={process.env.PUBLIC_URL + "/"}
      >
        <img
          loading="lazy"
          src={"/assets/img/logo/logo-" + logoColor + ".png"}
          alt="Logo"
        />
      </a>
    </div>
  );
};

export default HeaderLogo;
