import React from "react";

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="contacts-items">
        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>
              <a href="mailto:company@domain.com">linstitut.julie@gmail.com</a>
            </p>
          </div>

          <div className="contacts-item-title">
            <h6>Email</h6>
          </div>
        </div>

        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>25 Bis Rue Jules Ferry, 91430 Igny, France</p>
          </div>

          <div className="contacts-item-title">
            <h6>Adresse</h6>
          </div>
        </div>

        <div className="contacts-item">
          <div className="contacts-item-description">
            <p>
              <a href="tel:0611202043">06-11-20-20-43</a>
            </p>
          </div>

          <div className="contacts-item-title">
            <h6>Numéro de téléphone</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
