import React from "react";
import ReservationModal from "../modal/ReservationModal";
import moment from "moment";
import "moment/locale/fr";
// import api from "../../services/api";
const BookingCard = ({
  title,
  link,
  soins,
  date,
  startTime,
  endTime,
  oldBooking,
  id,
  service,
  price,
  duration,
  soin,
}) => {
  return (
    <div
      className="service-card card shadow-sm mx-auto"
      style={{
        minHeight: "100px",
      }}
    >
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <p>Date du rendez-vous : {moment(date).format("DD/MM/YYYY")} </p>
        <p>Heure du rendez-vous : {startTime} </p>
        <p>Service : {service}</p>
        <p>Soin : {soin}</p>
        <p>Prix : {price} euros</p>
        <p>Durée : {duration} minutes</p>
        <div className="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </div>
  );
};

export default BookingCard;
