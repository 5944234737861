import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Footer from "../blocks/footer/Footer";

import PageTitleHome from "../blocks/page-title/PageTitleHome";
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from "../blocks/services/ServicesHome";
import AboutsUs from "../blocks/about/AboutUs";
import Newsletter from "../blocks/newsletter/Newsletter";
import { Toaster } from "react-hot-toast";

const Home = () => {
  document.body.classList.add("home");
  document.body.classList.add("header-absolute-true");

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      document.body.classList.remove("home");
      document.body.classList.remove("header-absolute-true");
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Accueil | Julie Bengueddach - Esthéticienne professionelle{" "}
        </title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Offrez-vous des soins visages, soins corps, épilations, microblading, microshading, maquillage, manucure et beauté des pieds avec Julie Bengueddach, esthéticienne professionnelle à domicile à Igny, France."
        />
        <meta
          name="keywords"
          content="esthéticienne, domicile, Igny, soins visages, soins corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Toaster />

      <main id="main" className="site-main content-no-spacing">
        <div className="content">
          <div className="clearfix">
            <PageTitleHome />

            <ContactsUs />

            <ServicesHome />

            <AboutsUs />

            <Newsletter />
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Home;
