import React from "react";
const Actions = () => {
  return (
    <div className="actions">
      <div className="actions-items">
        <a
          className="actions-item"
          href="https://widget.treatwell.fr/salon/100051594/menu/"
        >
          <span>Réserver</span>
        </a>
      </div>
    </div>
  );
};

export default Actions;
