import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";

import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleServices from "../blocks/page-title/PageTitleServices";
import ServicesContent from "../blocks/services/ServicesContent";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Services | Julie Bengueddach - Esthéticienne professionnelle
        </title>
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Découvrez les différents services proposés par Julie Bengueddach, esthéticienne professionnelle à Igny, Paris. Profitez de soins visage, soins corps, épilation, microblading, microshading, maquillage, manucure et beauté des pieds."
        />
        <meta
          name="keywords"
          content="esthéticienne, Igny, Paris, soins visage, soins corps, épilation, microblading, microshading, maquillage, manucure, beauté des pieds"
        />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <PageTitleServices />

        <div id="page-content" className="spacer p-top-xl">
          <ServicesContent />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default Services;
