import React from "react";

const AboutsUs = () => {
  return (
    <section id="about-us" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="row gutter-width-sm">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="title">
              <h2>
                Offrez-vous un moment de bien-être et de détente grâce à mes 18
                ans d’expérience dans le domaine de l’esthétique qui est ma
                passion. Vous offrir un instant rien qu’à vous pour une
                relaxation garantie !
              </h2>
            </div>
          </div>
        </div>

        <div className="row gutter-width-sm justify-content-end">
          <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
            <div className="description">
              <p>
                En tant que passionnée du monde de la beauté et du bien-être,
                j’ai choisi ce métier pour aider les personnes à se sentir mieux
                dans leur peau et leur apporter un moment de détente et de
                relaxation. Mon objectif est de vous offrir une expérience
                personnalisée en fonction de vos besoins et de vos envies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutsUs;
