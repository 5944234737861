import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../reducers/user";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const SignUpForm = ({ toggleForm, page }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phonenumber: "",
  });

  const successMessage = "Inscription réussie";
  const errorMessage = "Quelque chose ne va pas. Réessayez plus tard!";
  const delay = 5000;
  const user = useSelector((state) => state.user.value);

  const submitForm = async (e) => {
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }
    try {
      const { firstname, lastname, phonenumber, email, password } = formValues;
      const response = await api.post(`/users/signup`, {
        firstname,
        lastname,
        phonenumber,
        email,
        password,
      });
      if (response.result) {
        const { firstname, lastname, token } = response;
        if (response.token) api.setToken(token);
        if (user) dispatch(login({ firstname, lastname, token }));
        page ? history.push(`/${page}`) : history.push("/");
        toast.success(
          `Bienvenue ${
            firstname[0].toUpperCase() + firstname.slice(1).toLowerCase()
          } 😁, vous pouvez désormer réserver vos soins sur le site web.`,
          { duration: 5000 }
        );
        return;
      }

      if (!response.result) {
        callAlert(response.error, "danger");
        return;
      }
    } catch (error) {
      callAlert(errorMessage, "danger");
    }
  };

  const removeAlert = () => {
    setTimeout(() => {
      var element = document.querySelector("#alert");
      element.classList.remove("fadeIn");
      element.classList.add("fadeOut");
      setTimeout(() => {
        element.remove();
      }, 900);
    }, delay);
  };

  const callAlert = (message, alertClass) => {
    if (!document.querySelector("#alert")) {
      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        alertClass +
        '">' +
        message +
        "</div>";
      var element = document.querySelector(".newsletter-form");
      if (element) {
        element.insertAdjacentHTML("beforeend", alert);

        removeAlert();
      }
    }
  };

  const handleInputChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={submitForm} className="newsletter-form top-no-space">
      <Toaster />
      <div className="input-group">
        <label htmlFor="email">Email</label>
        <input
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          id="email"
          type="email"
          placeholder="Adresse email"
          required
          className="mt-0 mb-4"
        />
        <label htmlFor="password">Mot de passe</label>
        <input
          name="password"
          value={formValues.password}
          onChange={handleInputChange}
          id="password"
          type="password"
          placeholder="Minimum 6 caractères,et 1 chiffre"
          required
          className="mt-0 mb-4"
        />
        <label htmlFor="firstname">Prénom</label>
        <input
          name="firstname"
          value={formValues.firstname}
          onChange={handleInputChange}
          id="firstName"
          type="text"
          placeholder="Prénom"
          required
          className="mt-0 mb-4"
        />
        <label htmlFor="lastname">Nom</label>
        <input
          name="lastname"
          value={formValues.lastname}
          onChange={handleInputChange}
          id="lastName"
          type="text"
          placeholder="Nom"
          required
          className="mt-0 mb-4"
        />
        <label htmlFor="phonenumber">Numéro de téléphone</label>
        <input
          name="phonenumber"
          value={formValues.phonenumber}
          onChange={handleInputChange}
          id="phone"
          type="text"
          placeholder="Exemple : 0626871234"
          required
          className="mt-0 mb-4"
        />
      </div>
      <button type="submit" className="btn btn-primary">
        S'inscrire
      </button>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => toggleForm("signIn")}
      >
        Vous êtes déjà inscrit ?{" "}
        <span className=" text-primary">Connectez-vous !</span>
      </button>
    </form>
  );
};

export default SignUpForm;
