import React, { useState } from "react";
import axios from "axios";
import { apiURL } from "../../services/config";
import { useHistory } from "react-router-dom";

const ResetPasswordForm = ({ token }) => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [alertClass, setAlertClass] = useState("");
  const [alertTimeout, setAlertTimeout] = useState("");
  const successMessage =
    "Votre mot de passe à été réinitialisé avec succès ! vous pouvez vous connectez";
  const warningMessage = "Veuillez remplir correctement le formulaire !";
  const errorMessage =
    "Une erreur s'est produite. Veuillez réessayer plus tard.";
  const [loading, setLoading] = useState(false);
  const delay = 5000;

  const submitForm = async (e) => {
    e.preventDefault();
    let responseMessage = "";
    let alertClass = "";
    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }
    setLoading(true);
    if (password && confirmPassword && password === confirmPassword) {
      axios
        .post(`${apiURL}/users/reset-password`, {
          password,
          repassword: confirmPassword,
          token,
        })
        .then((response) => {
          if (response.data.result) {
            responseMessage = successMessage;
            alertClass = "success";
            setSuccess(true);
          } else {
            responseMessage = response.data.error;
            alertClass = "danger";
          }
          callAlert(responseMessage, alertClass);
          setLoading(false);
          setPassword("");
          setConfirmPassword("");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
      return;
    } else {
      setLoading(false);
      responseMessage = warningMessage;
      alertClass = "warning";
      callAlert(responseMessage, alertClass);
      return;
    }
  };

  const removeAlert = () => {
    clearTimeout(alertTimeout);

    setAlertTimeout(
      setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");

        setTimeout(function () {
          element.remove();
        }, 900);
      }, delay)
    );
  };

  const callAlert = (message, alertClass) => {
    if (!document.querySelector("#alert")) {
      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector(".login-form");

      if (element) {
        element.insertAdjacentHTML("beforeend", alert);

        removeAlert();
      }
    }
  };

  return (
    <div style={{ margin: "auto", minWidth: "50%" }}>
      {!success ? (
        <form onSubmit={submitForm} className="login-form">
          <div className="form-group">
            <label htmlFor="password">Nouveau mot de passe</label>
            <input
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
              className="form-control"
              placeholder="Entrez votre nouveau mot de passe..."
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
            <input
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              type="password"
              className="form-control"
              placeholder="Confirmez votre nouveau mot de mot de passe..."
              required
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary mr-3"
              disabled={loading}
            >
              {loading ? "Chargement..." : "Réinitialiser le mot de passe"}
            </button>
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={() => {
                history.push("/");
              }}
            >
              Page d'accueil
            </button>
          </div>
        </form>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <div className="alert alert--shadow alert-success">
            {successMessage}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              history.push("/");
            }}
          >
            Page d'accueil
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;
