import React from "react";
import Header from "../header/Header";
const PageTitleHome = () => {
  return (
    <section
      id="page-title"
      className="block with-img"
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681654400/fond-ecran-mykery_spe9qb_nsdnyj.png)",
      }}
    >
      <Header logoColor="dark" />
      <div className="wrapper text-center d-flex">
        <div className="align-self-center w-100">
          <div className="title">
            <h1 className="large">L'institut de Julie</h1>
          </div>

          <div className="description spacer p-top-lg">
            <p>Soins exclusivement pour femmes</p>
          </div>

          <div className="spacer p-top-lg no-space">
            <a
              href="#contact-us"
              smooth={"true"}
              duration={0}
              className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id"
            >
              Défiler
            </a>
          </div>

          <nav className="languages lang-position">
            <ul className="nav">
              <li className="lang-item current-lang">
                <a title="fr" href={process.env.PUBLIC_URL + "/"}>
                  fr
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleHome;
