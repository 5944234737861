import React, { Component, Fragment } from "react";
import SwipeBox from "fslightbox-react";

class SwipeBoxComponent extends Component {
  state = {
    isVisible: false,
    slide: 0,
  };
  showSlide = (slide, event) => {
    event.preventDefault();
    this.setState({
      isVisible: !this.state.isVisible,
      slide: slide,
    });
  };

  render() {
    return (
      <Fragment>
        <SwipeBox
          toggler={this.state.isVisible}
          slide={this.state.slide}
          urls={[
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/48504e32-15a1-419d-8a72-62e473feb05b_gj7yj1.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/58235dc3-7b7f-46a4-b17e-0238d76dac08_cumjrt.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/654d05d0-5f7a-4ed1-8043-578fd248b217_rkuwvd.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/069ab167-23dc-4026-968c-39e60a119109_ftrc2n.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/bd4437d6-032c-4990-8160-81f8ec981ba5_crnnmh.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070907/9deb17ba-4cc7-488b-ac9a-06a6782e66b8_ynnh2d.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/28c3edc4-4c45-4cb8-8c5e-9475262ec6be_sw3emv.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/72e66ae5-f264-4d3a-ad09-5e9cbb6f93f7_m47yt1.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/6413bc5b-ecd9-470d-a54d-2b65236753e4_idoaxd.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/9f39a7d6-27f0-4c34-9b95-3cd6e75f06a0_nx1iiw.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/9f39a7d6-27f0-4c34-9b95-3cd6e75f06a0_nx1iiw.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070906/003fb902-57ce-422c-a9ed-83924409e359_ur4idy.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/c9f8b4d5-8f30-466b-984f-cbfbce9042cf_r6pkor.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/79cfae22-17c3-43c4-ac10-c25c33cbe5aa_mtwuuq.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/94bfe96f-108a-410b-a045-4638c2d9d9f1_epzpg5.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/f5a735ac-eece-4de8-8442-161f3c5fbf15_tlpdxp.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/ed159e5b-5f40-49e4-9a62-b8f1e4366d42_s2f4rx.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/aae0387c-aeaa-412e-ba45-5cf230c0a224_hcvjbo.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/80cfe903-5e76-4fd0-977b-bc2ca1c9c152_or9d9n.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070905/0cf3faf2-2f72-4dc8-8923-6b877ee05792_jwmnxy.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/4fd03bf7-ed0c-468e-b99a-3fed2698b567_latmbu.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/ccb0825b-f05a-4058-860e-b107599b0fd1_fi2k5j.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/67435b17-e1bf-4056-93b5-83a265bde9fd_flk7rv.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/f44dfd65-9692-42cf-b0ea-2a61c4923f4e_ssuipx.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070904/c97ca1e1-2b71-49a1-8dd0-b6d22d42d329_tzkzp8.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/429a5095-d41d-4203-ac17-3e3881e6c7c2_aepx6f.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/7e11053e-2544-4793-819d-cab9b2f806af_gjavlq.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/6839d6b4-75a8-4cd6-8591-1429663ca1a8_vexs9u.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/2e25700f-0e67-435b-9243-963bef174957_ogqhqf.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/f4939084-27bb-4956-939a-91032979b3b8_hib0pk.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070903/be62d81d-47e8-41a0-bee9-8917c91e7eeb_qjwjej.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070902/108992e6-e2d4-4e4e-aa32-409f453f1eff_l2bsax.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070902/b1fa18d8-9602-4609-b560-4f5feee97c84_sfefuc.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070901/8a7c6e57-58c1-4cfe-b53d-f1230614498d_sv6tcu.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070901/d3ad153f-c1fa-483d-8884-b0e6e74366ad_pmzrro.jpg",
            "https://res.cloudinary.com/dnfguloym/image/upload/q_40/v1691070901/34de1762-4155-44db-a090-59f64a3417b7_ihw9s8.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575873/WhatsApp_Image_2023-05-08_at_21.19.08_r23dup.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575874/WhatsApp_Image_2023-05-08_at_21.19.06_g3b8in.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656789/43c183f7-e6ee-404d-bc2f-a989ad5b066e_2_skadwh.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681663817/230113a5-4d57-45e1-a9f4-b5f87847ca29_muem9o.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656791/c83e19a3-1467-4590-a553-d05392cae11b_2_gv0buk.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656790/498d57fb-4ab6-4078-b923-4a098c7d988a_2_iybjt1.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656791/91186aa5-c99e-4e86-8814-e46088030282_2_zooxn8.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656790/ed9254d8-c956-4d61-8142-1612e2f3e292_2_nwnmoh.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656791/5092598f-4aa8-468e-ba88-b67d5c1aa043_aqkzzo.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656789/e28d6bb6-7494-48d9-8d2d-952998b78c09_2_momzgu.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575873/WhatsApp_Image_2023-05-08_at_21.19.07_brtl1t.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575873/WhatsApp_Image_2023-05-08_at_21.19.09_1_ptcf4u.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575872/WhatsApp_Image_2023-05-08_at_21.19.09_wv2fpi.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575872/WhatsApp_Image_2023-05-08_at_21.19.09_2_ndl94f.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575872/WhatsApp_Image_2023-05-08_at_21.19.12_1_c8rgb1.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575872/WhatsApp_Image_2023-05-08_at_21.19.09_3_lwiwr3.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.10_1_ndlkgd.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.10_1_ndlkgd.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.11_nrtlq1.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.12_rdxa8l.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.10_suybo8.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575871/WhatsApp_Image_2023-05-08_at_21.19.13_ofhx7d.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575870/WhatsApp_Image_2023-05-08_at_21.19.13_1_hyabaw.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575869/WhatsApp_Image_2023-05-08_at_21.19.15_acyyyz.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575869/WhatsApp_Image_2023-05-08_at_21.19.14_1_k7wiae.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575868/WhatsApp_Image_2023-05-08_at_21.19.17_y3yqfk.jpg",
            "https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575868/WhatsApp_Image_2023-05-08_at_21.19.18_oia261.jpg",
          ]}
        />

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(1, event)}
            title="Face & Body | Image 1"
            ref={this.swipeImg}
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575873/WhatsApp_Image_2023-05-08_at_21.19.08_r23dup.jpg"
                  alt="Face & Body"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(2, event)}
            title="Hair Treatments | Image 2"
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1683575874/WhatsApp_Image_2023-05-08_at_21.19.06_g3b8in.jpg"
                  alt="Hair Treatments"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(7, event)}
            title="Hands & Feets | Image 3"
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656791/5092598f-4aa8-468e-ba88-b67d5c1aa043_aqkzzo.jpg"
                  alt="Hands & Feets"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(8, event)}
            title="Haircuts for Men | Image 4"
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656789/e28d6bb6-7494-48d9-8d2d-952998b78c09_2_momzgu.jpg"
                  alt="Haircuts for Men"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(5, event)}
            title="Hair | Image 5"
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656791/91186aa5-c99e-4e86-8814-e46088030282_2_zooxn8.jpg"
                  alt="Hair"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <a
            onClick={(event) => this.showSlide(6, event)}
            title="Manicure | Image 6"
            className="gallery-item swipebox"
            href="assets/img/placeholder/780x500.jpg"
            rel="gallery"
          >
            <div className="img object-fit">
              <div className="object-fit-cover">
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/dlrbciium/image/upload/q_40/v1681656790/ed9254d8-c956-4d61-8142-1612e2f3e292_2_nwnmoh.jpg"
                  alt="Manicure"
                />
              </div>
            </div>

            <div className="img-bg-color"></div>
          </a>
        </div>
      </Fragment>
    );
  }
}

export default SwipeBoxComponent;
