import React, { Component } from "react";
import axios from "axios";
import { apiURL } from "../../services/config";
class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        services: "",
        email: "",
        message: "",
      },
      successMessage: "Le message a été envoyé avec succès !",
      warningMessage:
        "Remplissez le formulaire correctement, s'il vous plaît !",
      errorMessage: "Quelque chose ne va pas. Réessayez plus tard!",
      alertClass: "",
      responseMessage: "",
      alertTimeout: "",
      delay: 5000,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }

    this.setState({ isSubmitting: true });
    const response = await axios.post(
      `${apiURL}/requests/create`,
      this.state.values
    );
    if (response.data.status === "success") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
          message: "",
        },
      });
      this.callAlert(this.state.successMessage, response.data.status);
      return;
    }

    if (response.data.error === "email") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
          message: "",
        },
      });

      this.callAlert(this.state.warningMessage, "warning");
      return;
    }

    if (response.data.error === "error") {
      this.setState({
        values: {
          ...this.state.values,
          email: "",
        },
      });
      this.callAlert(this.state.errorMessage, response.data.error);
      return;
    }
  };

  removeAlert = () => {
    clearTimeout(this.state.alertTimeout);
    this.setState({
      alertTimeout: setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");
        setTimeout(function () {
          element.remove();
        }, 900);
      }, this.state.delay),
    });
  };

  callAlert = (message, type) => {
    if (!document.querySelector("#alert")) {
      if (type === "success") {
        this.setState({ alertClass: "success" });
      }

      if (type === "error") {
        this.setState({ alertClass: "danger" });
      }

      if (type === "warning") {
        this.setState({ alertClass: "warning" });
      }

      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        this.state.alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector("#cf-1");

      element.insertAdjacentHTML("beforeend", alert);

      this.removeAlert();
    }
  };

  handleInputChange = (e) =>
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });

  render() {
    return (
      <form
        method="post"
        onSubmit={this.submitForm}
        id="cf-1"
        className="contact-form"
      >
        <div className="form-group form-group-lg">
          <p className="input-group gutter-width-sm no-space">
            <span className="gutter-width">
              <label
                className="form-label-lg before after mb-0"
                htmlFor="cf-1-email"
              >
                Email *
              </label>

              <input
                name="email"
                value={this.state.values.email}
                onChange={this.handleInputChange}
                type="text"
                id="cf-1-email"
                placeholder="Votre Email..."
                required="required"
              />
            </span>
            <span className="gutter-width">
              <label
                className="form-label-lg before after mb-0"
                htmlFor="cf-1-services"
              >
                Choisir un service *
              </label>

              <select
                name="services"
                id="cf-1-services"
                value={this.state.values.services}
                onChange={this.handleInputChange}
              >
                <option value="Soin du visage">Soin du visage</option>
                <option value="Soin du corps">Soin du corps</option>
                <option value="Manucure et Beauté des pieds">
                  Manucure et Beauté des pieds
                </option>
                <option value="Épilation">Épilation</option>
                <option value="Microblading / Microshading">
                  Microblading / Microshading
                </option>
                <option value=">Maquillage Cils et Sourcils">
                  Maquillage Cils et Sourcils
                </option>
                <option value="Autres">Autres</option>
              </select>
            </span>
          </p>
        </div>

        <div className="form-group form-group-lg">
          <label
            className="form-label-lg before after mb-0"
            htmlFor="cf-1-message"
          >
            Message *
          </label>

          <textarea
            name="message"
            value={this.state.values.message}
            onChange={this.handleInputChange}
            id="cf-1-message"
            placeholder="Votre message ici..."
            required="required"
          ></textarea>
        </div>

        <div className="form-group form-group-lg mb-0">
          <button type="submit" className="btn btn-outline-secondary">
            Envoyer
          </button>
        </div>
      </form>
    );
  }
}

export default ContactForm;
