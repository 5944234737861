import React from "react";
import ReservationModal from "../modal/ReservationModal";
import SearchModal from "../modal/SearchModal";
import { useSelector } from "react-redux";
const ServiceCard = ({ title, link, soins }) => {
  const user = useSelector((state) => state.user.value);
  
  return (
    <div
      className="service-card card shadow p-3 mb-4 rounded"
      style={{
        width: "90%",
        minHeight: "200px",
        height: "1000%",
      }}
    >
      <div className="card-body">
        <h2 className="card-title">{title}</h2>
        <ul className="list-unstyled">
          {soins.products.map((soin, index) => {
            return (
              <li key={index} className="card-text">
                <span className="card-label">{soin.title}</span>

                {soin.prestations.map((product, subIndex) => {
                  return (
                    <div
                      key={subIndex}
                      className="d-flex justify-content-between align-items-center my-3 w-100 flex-wrap aling-items-center"
                    >
                      <div className="d-flex flex-column">
                        <span className="card-value">
                          Durée: {product.time} minutes
                        </span>
                        <span className="card-value">
                          Prix: {product.price} €
                        </span>
                      </div>
                      {/* <span className="card-value">
                        {user.token ? (
                          <ReservationModal
                            product={product}
                            title={soin.title}
                            service={title}
                          />
                        ) : (
                          <SearchModal text="Réserver" reservation={true} page="reservation-page"/>
                        )}
                      </span> */}
                    </div>
                  );
                })}
                <br />

                <br />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ServiceCard;
