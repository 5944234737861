import React, { useState } from "react";
import api from "../../services/api";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../reducers/user";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const SignInForm = ({ toggleForm, page }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertTimeout, setAlertTimeout] = useState("");

  const delay = 5000;

  const submitForm = async (e) => {
    const successMessage = "Connexion réussie";
    const errorMessage = "Quelque chose ne va pas. Réessayez plus tard!";
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }
    try {
      const response = await api.post(`/users/signin`, { email, password });
      if (response.result) {
        const { firstname, lastname, token } = response;

        if (response.token) api.setToken(token);
        if (user) dispatch(login({ firstname, lastname, token }));
        page ? history.push(`/${page}`) : history.push("/");
        toast.success(
          `Ravie de vous revoir ${
            firstname[0].toUpperCase() + firstname.slice(1).toLowerCase()
          } 😁`
        );
        return;
      }

      if (!response.result) {
        callAlert(response.error, "danger");
        return;
      }
    } catch (error) {
      callAlert(errorMessage, "danger");
    }
  };

  const removeAlert = () => {
    clearTimeout(alertTimeout);

    setAlertTimeout(
      setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");

        setTimeout(function () {
          element.remove();
        }, 900);
      }, delay)
    );
  };

  const callAlert = (message, alertClass) => {
    if (!document.querySelector("#alert")) {
      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector(".login-form");

      if (element) {
        element.insertAdjacentHTML("beforeend", alert);

        removeAlert();
      }
    }
  };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "email") {
      setEmail(value);
    }

    if (name === "password") {
      setPassword(value);
    }
  };

  return (
    <form onSubmit={submitForm} className="login-form">
      <Toaster />
      <h3 className="text-center text-light">
        Connectez-vous pour pouvoir réserver
      </h3>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          name="email"
          value={email}
          onChange={handleInputChange}
          id="email"
          type="email"
          className="form-control"
          placeholder="Entrez votre adresse email..."
          required
        />
      </div>

      <div className="form-group">
        <label htmlFor="password">Mot de passe</label>
        <input
          name="password"
          value={password}
          onChange={handleInputChange}
          id="password"
          type="password"
          className="form-control"
          placeholder="Mot de passe"
          required
        />
      </div>
      <div>
        <button type="submit" className="btn btn-primary">
          Se connecter
        </button>
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => toggleForm("signUp")}
        >
          C'est votre première fois ?{" "}
          <span className=" text-primary">Inscrivez-vous !</span>
        </button>
      </div>
      <button
        type="button"
        className="btn btn-outline-secondary"
        onClick={() => toggleForm("forgotPassword")}
      >
        Vous avez oublié votre mot de passe ?{" "}
        <span className=" text-primary font-weight-light">
          Réinitialisez votre mot de passe !
        </span>
      </button>
    </form>
  );
};

export default SignInForm;
