import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import HeaderLogo from "../../blocks/logo/HeaderLogo";
import SearchModal from "../../components/modal/SearchModal";
import ModalMenuPrimary from "../../blocks/menu/ModalMenuPrimary";
import Actions from "../../blocks/actions/Actions";
import { connect } from "react-redux";

class MenuModal extends Component {
  constructor(context) {
    super(context);

    this.state = {
      showModal: false,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ showModal: true });
  }

  close() {
    this.setState({ showModal: false });
  }

  render() {
    return (
      <Fragment>
        <div className="menu-toggle">
          <button
            onClick={this.open.bind(this)}
            type="button"
            className="btn btn-lg btn-link border-0 p-0 min-w-auto m-2"
            data-toggle="modal"
            data-target="#menu-modal"
          >
            <span
              className={
                this.props.logoColor === "light" ? "text-dark" : "text-light"
              }
            >
              Menu
            </span>
          </button>
        </div>

        <Modal
          className="menu-modal"
          show={this.state.showModal}
          onHide={this.close}
          backdrop={false}
          aria-labelledby="menu-modal"
          aria-hidden="true"
          dialogClassName="modal-full"
          ref={(node) => (this.chart = node)}
        >
          <div className="wrapper">
            <div className="modal-content">
              <div className="modal-header modal-header-top">
                <div className="header-content d-flex justify-content-between w-100">
                  <div className="header-left align-self-center">
                    <div className="links">
                      <div className="links-items">
                        <div className="links-item">
                          <a
                            href={process.env.PUBLIC_URL + "/"}
                            className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto"
                          >
                            Accueil
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="header-center align-self-center">
                    <HeaderLogo logoColor="dark" />
                  </div>

                  <div className="header-right d-flex justify-content-end">
                    <div className="d-flex align-items-center">
                      {!this.props.user.token && (
                        <SearchModal logoColor="dark" />
                      )}

                      <div className="menu-close-toggle">
                        <button
                          onClick={this.close}
                          type="button"
                          className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          Fermer
                        </button>
                      </div>
                    </div>

                    <Actions />
                  </div>
                </div>
              </div>

              <div className="modal-body modal-body-centered">
                <div className="animated fadeinright w-100">
                  <ModalMenuPrimary />

                  <div className="modal-text">
                    <p>© Salon / Institut de beauté de Julie Bengueddach</p>
                  </div>
                </div>
              </div>

              <div className="modal-footer">fr</div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.value,
  };
};

export default connect(mapStateToProps, null)(MenuModal);
