import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Loading from "../blocks/loading/Loading";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";
import BookingCard from "../components/card/BookingCard";
import api from "../services/api";
import { useDispatch } from "react-redux";
import { logout } from "../reducers/user";
import toast, { Toaster } from "react-hot-toast";

function UserProfil() {
  const dispatch = useDispatch();
  const [showCurrentBookings, setShowCurrentBookings] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [nom, setNom] = useState("Nom");
  const [prenom, setPrenom] = useState("Prénom");
  const [email, setEmail] = useState("Email");
  const [numeroTelephone, setNumeroTelephone] = useState("Numéro de téléphone");
  // État pour stocker les réservations passées
  const [reservationsPassee, setReservationsPassee] = useState([]);

  // État pour stocker les réservations en cours
  const [reservationsEnCours, setReservationsEnCours] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get("/users/profile");
        const { books, user, result } = response;

        if (result) {
          setNom(user.firstname);
          setPrenom(user.lastname);
          setEmail(user.email);
          setNumeroTelephone(user.phonenumber);
          const reservationsEnCours = books.filter((reservation) => {
            const startDate = new Date(
              reservation.date + "T" + reservation.startTime
            );
            const currentDate = new Date();
            return startDate > currentDate;
          });
          const reservationsPassee = books.filter((reservation) => {
            const startDate = new Date(
              reservation.date + "T" + reservation.startTime
            );
            const currentDate = new Date();
            return startDate <= currentDate;
          });

          // Mettre à jour les états avec les réservations filtrées
          setReservationsEnCours(reservationsEnCours);
          setReservationsPassee(reservationsPassee);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    const response = await api.put("/users", {
      firstname: nom,
      lastname: prenom,
      email: email,
      phonenumber: numeroTelephone,
    });
    const { user, result, error } = response;

    if (result) {
      setNom(user.firstname);
      setPrenom(user.lastname);
      setEmail(user.email);
      setNumeroTelephone(user.phonenumber);
    } else {
      console.log("erreur", error);
    }
    setEditMode(false);
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Profil | Julie Bengueddach - Esthéticienne professionelle</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="google-site-verification" content="-sL3qrR8XuJRi2vK6gvyph3PkILJ22ISSOo35kEVpXo" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="Page de profil de l'utilisateur avec ses informations personnelles et les rendez-vous pris avec l'esthéticienne."
        />
        <meta name="keywords" content="" />
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="light" />

      <main id="main" className="site-main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 ">
              <h2>Informations personnelles</h2>
              <div className="card shadow-sm">
                <div className="card-body">
                  {editMode ? (
                    <Fragment>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={nom}
                        onChange={(e) => setNom(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={prenom}
                        onChange={(e) => setPrenom(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={numeroTelephone}
                        onChange={(e) => setNumeroTelephone(e.target.value)}
                      />
                    </Fragment>
                  ) : (
                    <Fragment>
                      <p>Nom : {nom}</p>
                      <p>Prénom : {prenom}</p>
                      <p>Email : {email}</p>
                      <p>Numéro de téléphone : {numeroTelephone}</p>
                    </Fragment>
                  )}
                  <div className="d-flex justify-content-end align-items-center">
                    {editMode ? (
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={handleSaveClick}
                      >
                        Enregistrer
                      </button>
                    ) : (
                      <button
                        className="btn btn-link btn-sm"
                        onClick={handleEditClick}
                      >
                        Editer
                      </button>
                    )}
                  </div>
                  <button
                    className="btn btn-link btn-sm btn-danger mt-3 text-white"
                    onClick={handleLogoutClick}
                  >
                    Déconnexion
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-12">
              <div className="">
                <h2>Réservations</h2>
                <button
                  className="btn btn-link btn-sm"
                  onClick={() => setShowCurrentBookings(!showCurrentBookings)}
                >
                  {showCurrentBookings
                    ? "Afficher les réservations passées"
                    : "Afficher les réservations en cours"}
                </button>
              </div>
              {showCurrentBookings ? (
                <Fragment>
                  {/* Afficher ici les cartes de réservation pour les réservations en cours */}
                  {reservationsEnCours.map((reservation, i) => {
                    return (
                      <BookingCard
                        key={i}
                        date={reservation.date}
                        startTime={reservation.startTime}
                        endTime={reservation.endTime}
                        oldBooking={false}
                        id={reservation.id}
                        service={reservation.service}
                        soin={reservation.soin}
                        price={reservation.price}
                        duration={reservation.duration}
                      />
                    );
                  })}
                </Fragment>
              ) : (
                <Fragment>
                  {/* Afficher ici les cartes de réservation pour les réservations passées */}
                  {reservationsPassee.map((reservation, i) => {
                    return (
                      <BookingCard
                        key={i}
                        date={reservation.date}
                        startTime={reservation.startTime}
                        endTime={reservation.endTime}
                        oldBooking={true}
                        id={reservation.id}
                        service={reservation.service}
                        soin={reservation.soin}
                        price={reservation.price}
                        duration={reservation.duration}
                      />
                    );
                  })}
                </Fragment>
              )}
              <div className="d-flex justify-content-end align-items-center mt-3"></div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </Fragment>
  );
}

export default UserProfil;
