import React, { useState } from "react";
import axios from "axios";
import { apiURL } from "../../services/config";

const ForgotPasswordForm = ({ toggleForm }) => {
  const [email, setEmail] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [alertTimeout, setAlertTimeout] = useState("");
  const [resetPassword, setResetPassword] = useState(false);
  const successMessage =
    "Vous allez recevoir un email pour réinitialiser le mot de passe !";
  const warningMessage = "Veuillez remplir correctemement le formulaire!";
  const errorMessage = "Réesayer plus tard !";
  const [loading, setLoading] = useState(false);
  const delay = 5000;

  const submitForm = async (e) => {
    e.preventDefault();
    let responseMessage = "";
    let alertClass = "";
    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }
    setLoading(true);
    if (email) {
      axios
        .post(`${apiURL}/users/forgot-password`, { email })
        .then((response) => {
          if (response.data.result) {
            responseMessage = successMessage;
            alertClass = "success";
          }
          if (response.data.error === "Informations manquantes") {
            responseMessage = "Informations manquantes";
            alertClass = "warning";
          }
          if (
            response.data.error === "Vous n'êtes pas inscrit avec cet email"
          ) {
            responseMessage = "Vous n'êtes pas inscrit avec cet email";
            alertClass = "danger";
          }
          callAlert(responseMessage, alertClass);
          setLoading(false);
          setEmail("");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });

      return;
    } else {
      responseMessage = warningMessage;
      alertClass = "warning";
      callAlert(responseMessage, alertClass);
      return;
    }
  };

  const removeAlert = () => {
    clearTimeout(alertTimeout);

    setAlertTimeout(
      setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");

        setTimeout(function () {
          element.remove();
        }, 900);
      }, delay)
    );
  };

  const callAlert = (message, alertClass) => {
    if (!document.querySelector("#alert")) {
      var alert =
        '<div id="alert" class="animated fadeIn alert alert--shadow alert-' +
        alertClass +
        '">' +
        message +
        "</div>";

      var element = document.querySelector(".login-form");

      if (element) {
        element.insertAdjacentHTML("beforeend", alert);

        removeAlert();
      }
    }
  };

  return (
    <div style={{ margin: "auto", minWidth: "50%" }}>
      <form onSubmit={submitForm} className="login-form">
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            type="email"
            className="form-control"
            placeholder="Entrez votre email..."
            required
          />
        </div>
        {!loading ? (
          <button type="submit" className="btn btn-primary">
            Réinitialiser le mot de passe
          </button>
        ) : (
          <button type="submit" className="btn btn-primary" disabled>
            Chargement...
          </button>
        )}
        <button
          onClick={() => toggleForm("signIn")}
          type="button"
          className="btn btn-secondary ml-3"
        >
          Retour à la connexion
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
